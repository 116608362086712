import React from 'react'
import PropTypes from 'prop-types'
import { uid } from 'react-uid'
import { navigate } from 'gatsby'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookIcon from '@mui/icons-material/Facebook'
import YoutubeIcon from '@mui/icons-material/YouTube'

import { classNames, scrollToTop } from '../../helper'
import { ReactComponent as RelutionLogo } from '../../images/relution-logo.svg'
import { ReactComponent as RelutionSignet } from '../../images/relution-signet.svg'
import { ReactComponent as MwayLogo } from '../../images/mway-logo.svg'

const socialIcons = {
	twitter: TwitterIcon,
	linkedin: LinkedInIcon,
	instagram: InstagramIcon,
	facebook: FacebookIcon,
	youtube: YoutubeIcon
}

const openCookieSettings = () => {
	if ('URLSearchParams' in window) {
		var searchParams = new URLSearchParams(window.location.search)
		searchParams.set('cmpscreen', undefined)
		var newRelativePathQuery =
			window.location.pathname + '?' + searchParams.toString()
		history.pushState(null, '', newRelativePathQuery)
	}
}

const PageFooter = ({
	directories,
	socialLinks,
	legalLinks,
	copyright,
	linkTextRelution,
	LinkRelution,
	linkTextMway,
	LinkMway,
	linkTextApps,
	LinkApps,
	toTopText,
	privacySettingsText,
	socialLinksTitle,
}) => {
	const finalSocialLinks = socialLinks
		? socialLinks.map((item) => ({
			...item,
			Icon: socialIcons[item.iconName || 'twitter'] || socialIcons['twitter'],
		}))
		: []

	return (
		<footer>
			<div className="pt-12 pb-8 bg-white border-off-white border-t-[1.25rem] overflow-hidden">
				<div className="box grid gap-y-10 hd:grid-cols-12">
					<div className="space-y-8 sm:flex sm:space-x-10 sm:space-y-0 sm:justify-between hd:col-span-2 hd:flex-col hd:space-x-0 hd:space-y-10 hd:justify-start">
						{LinkRelution && (
							<LinkRelution>
								<RelutionLogo className="w-[10.1875rem]" />
								{linkTextRelution && (
									<span className="sr-only">{linkTextRelution}</span>
								)}
							</LinkRelution>
						)}
					</div>
					{directories && (
						<div className="text-14 leading-1.2 uppercase break-words grid gap-x-15 gap-y-6 sm:grid-cols-2 md:grid-cols-4 hd:col-span-8 hd:pt-3">
							{directories.map((directory) => (
								<nav key={uid(directory)}>
									{directory.title && (
										<h2 className="pb-2 font-bold">{directory.title}</h2>
									)}
									<ul className="">
										{directory.items.map((item) => (
											<li key={uid(item)} className="">
												<item.Link className="block py-2">
													{item.linkText || item.title}
												</item.Link>
											</li>
										))}
									</ul>
								</nav>
							))}
						</div>
					)}
					<div className="space-y-8 sm:space-y-0 sm:flex sm:space-x-10 hd:flex-col hd:pt-1 hd:col-span-2 hd:ml-auto hd:space-x-0 hd:space-y-8 hd:space-y-reverse">
						{LinkApps && (
							<LinkApps className="flex p-3 max-w-[10.1875rem] space-x-3 bg-off-white text-12 uppercase leading-none hd:order-2">
								<RelutionSignet className="flex-none w-[1.4375rem]" />
								{linkTextApps && <span>{linkTextApps}</span>}
							</LinkApps>
						)}
						{finalSocialLinks && (
							<div className="text-14 leading-1.2 uppercase break-words flex items-center space-x-4 hd:order-1">
								{socialLinksTitle && (
									<h2 className="flex-none font-bold">{socialLinksTitle}</h2>
								)}
								<ul className="flex items-center space-x-4">
									{finalSocialLinks.map((item) => (
										<li key={uid(item)}>
											{item.Link && (
												<item.Link className="block w-8 h-8 text-rio-grande-green">
													{item.Icon && <item.Icon />}
													{item.linkText && (
														<span className="sr-only">
															{item.linkText || item.title}
														</span>
													)}
												</item.Link>
											)}
										</li>
									))}
								</ul>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className=" bg-off-white">
				<div className="relative box py-10">
					<div className="text-12 leading-1.2 xl:flex xl:justify-between xl:items-center">
						{legalLinks && (
							<nav>
								<ul className="space-y-3 sm:flex sm:divide-x sm:divide-charcoal sm:-ml-3 sm:space-y-0">
									{legalLinks.map((item) => (
										<li key={uid(item)} className="sm:px-3">
											<item.Link>{item.linkText || item.title}</item.Link>
										</li>
									))}
									{privacySettingsText && (
										<li className="sm:px-3">
											<a
												href="?cmpscreen"
												rel="nofollow"
												onClick={(event) => {
													event.preventDefault()
													openCookieSettings()
												}}
											>
												{privacySettingsText}
											</a>
										</li>
									)}
								</ul>
							</nav>
						)}
						{(copyright || LinkMway) && (
							<p
								className={classNames(
									'flex items-center space-x-3',
									legalLinks && 'mt-4 xl:mt-0',
								)}
							>
								{copyright && <span>{copyright}</span>}
							</p>
						)}
						<button
							type="button"
							className="absolute top-0 right-4 w-12 h-12 transform sm:-translate-x-1/2 -translate-y-1/2 bg-rio-grande-green text-white rounded-full shadow p-2 "
							onClick={() => scrollToTop()}
						>
							<ArrowUpwardIcon />
							{toTopText && <span className="sr-only">{toTopText}</span>}
						</button>
					</div>
				</div>
			</div>
		</footer>
	)
}

PageFooter.defaultProps = {
	directories: [],
}

PageFooter.propTypes = {
	directories: PropTypes.array,
	socialLinks: PropTypes.array,
	legalLinks: PropTypes.array,
	copyright: PropTypes.string,
	linkTextRelution: PropTypes.string,
	LinkRelution: PropTypes.func,
	linkTextMway: PropTypes.string,
	LinkMway: PropTypes.func,
	linkTextApps: PropTypes.string,
	LinkApps: PropTypes.func,
	toTopText: PropTypes.string,
	privacySettingsText: PropTypes.string,
	socialLinksTitle: PropTypes.string,
}

export default PageFooter
